/* eslint-disable */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { shuffle } from 'lodash';
import moment from 'moment'

import { PAGES, BASE_URL } from 'config';
import {
  FORMAT_GRAPH_DATA,
  numberToCurrency,
  minMaxOptionPrices,
  getImageUrl,
  mapStateToProps,
} from 'utilities';
import { Layout, Favourite } from 'containers';
import { TourList, ActivityList } from 'layouts';
import { Breadcrumbs, FaqAccordion, Gallery } from 'components';


class ActivityPage extends PureComponent {
  /**
   * Get gallery slides
   * @returns array
   */
  getGallerySlides() {
    const { pageContext, data } = this.props;

    const slideIDs = [
      pageContext.sliding_first_picture_id,
      pageContext.sliding_second_picture_id,
      pageContext.sliding_third_picture_id,
      pageContext.sliding_fourth_picture_id,
      pageContext.sliding_fifth_picture_id,
    ];

    return slideIDs
      .filter(id => id)
      .map(id => ({
        id,
        imageUrl: getImageUrl({
          id,
          images: data.allActivityImages.edges,
          width: 762,
          height: 468,
        }),
      }));
  }

  getOptionPrice(option) {
    const prices = [];

    prices.push(option.price);
    if (option.seasonal_pricings && option.seasonal_pricings.length > 0) {
      option.seasonal_pricings.forEach((seasonal) => {
        prices.push(seasonal.price);
      });
    }

    const max = Math.max(...prices);
    const min = Math.min(...prices);

    if (max == min) {
      return `$${min}`;
    } else {
      return `$${min} - $${max}`;
    }
  }

  getAvailability(item) {
    let availability = null;
    if (item.available_from && item.available_to) {
      availability = `Available From ${moment(item.available_from).format("DD MMM YYYY")} until ${moment(item.available_to).format("DD MMM YYYY")}`;
    } else if (item.available_from) {
      availability = `Available From ${moment(item.available_from).format("DD MMM YYYY")}`;
    } else if (item.available_to) {
      availability = `Available Until ${moment(item.available_to).format("DD MMM YYYY")}`;
    }
    return availability;
  }


  /**
   * Render
   */
  render() {
    const { pageContext, data, user } = this.props;

    // Accordion list
    const accordionList = pageContext.options.filter(option => option.hidden === false).filter(option => option.archived === false).map(item => ({
      ...item,
      question: `${item.name} (${this.getOptionPrice(item)})`,
      availability: this.getAvailability(item),
      answer: item.description,
    }));

    // Option prices
    const { min, max } = minMaxOptionPrices(pageContext.options.filter(option => option.hidden === false).filter(option => option.archived === false));
    // const { min, max } = minMaxOptionPrices(pageContext.options);

    // Tour list
    const tourList = FORMAT_GRAPH_DATA(data.allTourPages)
      .filter(item => pageContext.tour_ids.includes(item.api_id));

    // Activity list
    const activityList = shuffle(FORMAT_GRAPH_DATA(data.allActivityPages)
      .filter(item => item.api_id !== pageContext.api_id));

    // Favourites
    const FAVED = user.favorites.activities.includes(pageContext.api_id);

    // Breadcrumbs
    const breadcrumbs = [
      {
        name: PAGES.ACTIVITIES.TITLE,
        absolute_slug: PAGES.ACTIVITIES.PATH,
      },
      {
        name: pageContext.name,
        absolute_slug: pageContext.absolute_slug,
      },
    ];

    return (
      <Layout page="activity-page">
        {pageContext.no_index && (
          <Helmet
            title={pageContext.page_title}
            meta={[
              { name: 'description', content: pageContext.meta_description },
              { name: 'og:title', content: pageContext.page_title },
              { name: 'og:description', content: pageContext.meta_description },
              { name: 'robots', content: 'noindex' },
            ]}
            link={[{ rel: 'canonical', href: `${BASE_URL}/activities/${pageContext.slug}/` }]}
          />
        )}

        {!pageContext.no_index && (
          <Helmet
            title={pageContext.page_title}
            meta={[
              { name: 'description', content: pageContext.meta_description },
              { name: 'og:title', content: pageContext.page_title },
              { name: 'og:description', content: pageContext.meta_description },
            ]}
            link={[{ rel: 'canonical', href: `${BASE_URL}/activities/${pageContext.slug}/` }]}
          />
        )}

        <section className="l-activity-page">
          <div className="l-container">
            <Breadcrumbs list={breadcrumbs} />

            <div className={`l-activity-page__favourites ${FAVED ? 'is-faved' : ''}`}>
              <Favourite addOnID={pageContext.api_id} />
            </div>

            <div className="l-activity-page__grid">
              <div className="l-activity-page__main">
                <div className="l-activity-page__main-text">
                  <h1 className="c-heading c-heading--h1">
                    {pageContext.name}
                  </h1>

                  <p className="l-activity-page__main-location">
                    {pageContext.location}
                  </p>

                  <h2 className="c-heading c-heading--h2">
                    {numberToCurrency(min)}
                    {max !== min ? ` – $${max}` : null}
                  </h2>
                </div>

                <FaqAccordion
                  list={accordionList}
                  open
                />
              </div>

              <div className="l-activity-page__slider">
                <Gallery
                  slides={this.getGallerySlides()}
                  showPagination
                />
              </div>
            </div>
          </div>
        </section>

        {!!tourList.length && (
          <TourList
            heading={<h2 className="c-heading c-heading--h2">Tours you can add this activity to:</h2>}
            list={tourList}
            modifier="u-background--white"
            hideFilters
          />
        )}

        {!!activityList.length && (
          <ActivityList
            title="Activities you might also like"
            list={activityList}
            limit={4}
          />
        )}
      </Layout>
    );
  }
}

ActivityPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export const query = graphql`
  query {
    allTourPages(
      sort: {fields: [order], order: ASC},
      filter: {
                hidden_category: { eq: false },
              }
    ) {
      edges {
        node {
          id
          api_id
          name
          slug
          number_of_days
          starting_price
          destination_ids
          category_ids
          short_description_markdown
          parent_name
          absolute_slug
          picture_id
          small_map_id
          sliding_first_picture_id
          sliding_second_picture_id
          sliding_third_picture_id
          sliding_fourth_picture_id
          sliding_fifth_picture_id
          sliding_sixth_picture_id
          sliding_seventh_picture_id
          sliding_eighth_picture_id
          sliding_ninth_picture_id
          sliding_tenth_picture_id
          background_color
          parent_category_icon {
            id
            svg_text
          }
        }
      }
    }

    allActivityPages(
      filter: {
        is_accommodation_supplier: { eq: false },
        is_activity_supplier: { eq: true },
        guide_app_only: { ne: true },
      }
    ) {
      edges {
        node {
          id
          api_id
          name
          location
          absolute_slug
          sliding_first_picture_id
          no_index
          options {
            price
            hidden
            archived
            seasonal_pricings {
              id
              price
              available_from
              available_to
              hidden
            }
          }
        }
      }
    }

    allActivityImages {
      edges {
        node {
          api_id
          imgix_url
        }
      }
    }
  }
`;

export default connect(mapStateToProps)(ActivityPage);
